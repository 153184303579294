<template>
  <nav-header section="my-work"/>
  <gallery-browser :selGallery="selGallery" v-on:change="changeArticle" />
  <main class="main">
    <item-display :article="article" />
  </main>
  <footer-vue />
</template>

<script>
import NavHeader from '../components/NavHeader.vue'
import GalleryBrowser from '../components/GalleryBrowser.vue'
import ItemDisplay from '../components/ItemDisplay.vue'
import mdata from '../data/mdata.js'
import FooterVue from '../components/FooterView.vue'
export default {
  props: {
    galleryName: String,
    item: String
  },
  components: {
    NavHeader,
    GalleryBrowser,
    ItemDisplay,
    FooterVue
  },
  data() {
    return {
      gallery: {},
      selGallery: 0,
      article: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if(this.galleryName) {
        this.gallery = mdata.getGalleryByName(this.galleryName)
        if(this.gallery) {
          this.selGallery = mdata.galleryIndex(this.gallery)
          if(typeof this.item !== 'undefined'){
            this.article = this.gallery.articles[Number(this.item)]
          }
          else {
            this.article = this.gallery.articles[0]
          }
        }
      }
    },
    changeArticle(index) {
      this.article = this.gallery.articles[index]
    }
  },
  watch: {
    galleryName() {
      this.init()
    }
  }
}
</script>

<style>
.image-diviner{
  border:1px solid #666;
  min-height:100px;
}
</style>
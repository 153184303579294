<template>
  <footer class="footer">
    <div class="left">
      © Jenny Balson 2021
    </div>
    <div class="right">
      <div>
          For commissions or any further information please ring me on 07793 825754<br>
          or email me at <a href="mailto:jewellery@jennybalson.co.uk">jewellery@jennybalson.co.uk</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
  <div class="gallery">
    <div class="sc-left" v-on:mousedown="startLeft()" v-on:mouseup="stopLeft()">
      <img src="/img/sc-left.png" alt="previous">
    </div>
    <div class="galimages">
      <div class="galholder" ref="ghold">
        <div v-for="(item, index) in items" :key="item.article_id" class="prev" v-on:click="handleImageClick(index)">
          <img :src="getImageFile(item.images[0])" alt="">
        </div>
      </div>
    </div>
    <div class="sc-right" v-on:mousedown="startRight()" v-on:mouseup="stopLeft()">
      <img src="/img/sc-right.png" alt="next">
    </div>
  </div>
</template>

<script>
import { waitfor } from '../utils.js'
import mdata from '../data/mdata.js'
const scrolldist = 140


export default {
  props: {
    selGallery: Number
  },
  computed: {
    items() {
      return mdata.getGallery(this.selected)
    }
  },
  data() {
    return {
      selected: 0,
      imbase: process.env.VUE_APP_IMAGEBASE,
      mousedown: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init() {
      console.log('called init selGallery is', this.selGallery)
      if(typeof this.selGallery === 'undefined'){
        this.selected = mdata.getCurgal()
      }
      else {
        this.selected = this.selGallery
      }
      console.log('selected is', this.selected)
      mdata.setCurgal(this.selected)
    },
    goRight() {
      let ghold = this.$refs.ghold.getBoundingClientRect()
      let par = this.$refs.ghold.parentElement
      let ss = par.scrollLeft
      ss += scrolldist
      if(ss > ghold.width) {
        ss = ghold.width
      }
      par.scrollTo({ top: 0, left: ss, behavior: 'smooth'})
    },
    goLeft() {
      let par = this.$refs.ghold.parentElement
      let ss = par.scrollLeft
      ss -= scrolldist
      if(ss < 0) {
        ss = 0
      }
      par.scrollTo({ top: 0, left: ss, behavior: 'smooth'})
    },
    async startLeft() {
      this.mousedown = true
      this.goLeft()
      await waitfor(200)
      while(this.mousedown){
        this.goLeft()
        await waitfor(100)
      }
    },
    stopLeft() {
      this.mousedown = false
    },
    async startRight() {
      this.mousedown = true
      this.goRight()
      await waitfor(200)
      while(this.mousedown){
        this.goRight()
        await waitfor(100)
      }
    },
    getImageFile(image) {
      let fname = image.filename.replace(/\.gif/g, '.jpg')
      return this.imbase + '/120/' + fname
    },
    handleImageClick(which) {
      this.$emit('change', which)
    }
  },
  watch: {
    selGallery() {
      this.init()
    }
  }
}
</script>

<style>

</style>
<template>
  <div id="clickme" style="width:100%;height:600px;cursor:pointer;" v-on:click="goStart">
    <div class="intropanel" style="">
        <div class="imholder" ref="imholder">
            <img src="/img/imagegroup1.gif" alt="Jenny Balson" width="568" height="85">
            <img src="/img/imagegroup2.gif" alt="Jenny Balson" width="568" height="85">
            <img src="/img/imagegroup3.gif" alt="Jenny Balson" width="568" height="85">
        </div>
        <router-link to="/about-me"><img src="/img/jennybalsonlogo.gif" width="568" height="55"></router-link>
    </div>
  </div>
</template>

<script>
import { waitfor } from '../utils.js'
export default {
  data() {
    return {
      animating: true
    }
  },
  mounted() {
    this.slideshow()
  },
  methods: {
    async slideshow() {
      if(!this.$refs.imholder){
        return
      }
      const children = this.$refs.imholder.children
      await waitfor(50)
      while(this.animating) {
        for(let child of children) {
          child.style.opacity = 1
          await waitfor(8000)
          child.style.opacity = 0
          await waitfor(500)
        }
      }
      
    },
    goStart() {
      this.$router.push('/about-me')
    }
  }
}
</script>

<style>
.imholder img {
  display:block;
  transition:opacity 0.5s linear;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  opacity:0;
}

.imholder{
  overflow:hidden;
  position:relative;
}
</style>
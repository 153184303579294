<template>
  <div class="holder">
    <router-view />
  </div>
</template>
<script>
import './css/balson.css'
export default {
  
}
</script>

<style>

</style>

<template>
  <div class="header">
    <router-link to="/"><img src="/img/jenny-balson-jewellery.png" alt="Jenny Balson Jewellery"></router-link>
    <nav class="nav">
      <nav-item v-for="section in balson" :key="section.section_id" :section="section" :selected="sectionId"></nav-item>
    </nav>
  </div>
</template>

<script>
import mdata from '../data/mdata.js'
import NavItem from '../components/NavItem.vue'
export default {
  props: {
    section: String
  },
  components: {
    NavItem
  },
  data() {
    return {
      balson: mdata.all(),
      sectionId: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.sectionId = mdata.getSectionId(this.section)
    }
  },
  watch: {
    section() {
      this.init()
    }
  }
}
</script>

<style>

</style>
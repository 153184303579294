<template>
  <div :class="{sel: sel}">
    <div v-if="ismenu" class="menu-dropper">
      <div class="menu-title" v-on:click.stop="dropMenu"><span class="gt" :class="{showing: sel}">&gt;</span> {{ section.title }}</div>
      <div class="submenu" :class="{sel: dropped}">
        <router-link v-for="(s, index) in section.sections" :key="s.section_id" :to="getSublink(s)" :class="{sel: selected === index}" >{{ s.title }}</router-link>
      </div>
    </div>
    <div class="fixed-bop" v-if="dropped" v-on:click="closeMenu"></div>
    <router-link v-if="!ismenu" :to="'/' + linkify(section.title)"><span class="gt" :class="{showing: sel}">&gt;</span> {{ section.title }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    section: Object,
    selected: Number
  },
  data() {
    return {
      dropped: false
    }
  },
  computed: {
    ismenu() {
      return this.section.sections
    },
    sel() {
      return (this.selected === this.section.section_id)
    }
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.closeMenu)
  },
  methods: {
    getSublink(sub){
      return '/' + this.linkify(this.section.title) + '/' + this.linkify(sub.title)
    },
    linkify(title) {
      return title.toLowerCase().replace(/ /g,'-')
    },
    dropMenu() {
      if(!this.ismenu) {
        return
      }
      this.dropped = true
    },
    closeMenu() {
      this.dropped = false
    }
  },
  watch: {
    '$route.path': function () {
      console.log('saw path change')
      this.closeMenu()
    }
  }

}
</script>

<style>
.gt{
  opacity: 0;
  font-weight:normal;
}
.gt.showing{
  opacity:1;
}
.submenu {
  display:none;
  padding:12px;
  background-color:rgba(255,255,255,0.9);
  box-shadow: 3px 3px 3px rgba(0,0,0,0.4);
  position:absolute;
  width:90px;
  z-index:300;
}

.sel > a{
  font-weight: bold;
}

.submenu.sel{
  display:block;
  
}

.submenu.sel > a{
  font-weight:normal;
  padding:5px;
}

.submenu.sel > a:hover{
  color:#000;
}

.submenu.sel a.router-link-active{
  color:#333;
}

.submenu.sel a.router-link-active::before{
  content:"> ";
  margin-left:-13px;
}

.menu-dropper{
  cursor:pointer;
  background-color:#fff;
  z-index:200;
}

.menu-dropper:hover .menu-title{
  color:#000;
}

.fixed-bop{
  position:fixed;
  box-shadow: border-box;
  z-index:10;
  top:0;
  left:0;
  bottom:0;
  right:0;
  opacity:1;
}
</style>
<template>
  <div class="mainarea" :class="{viz: vismain}">
    <div class="left">
      <h3>{{ marticle.title }}</h3>
    </div>
    <div class="right">
      <img :src="getImageFile(marticle.images[1])" :alt="marticle.title" v-on:load="handleLoadImage">
    </div>
  </div>
</template>

<script>
import { waitfor } from '../utils.js'
export default {
  props: {
    article: Object
  },
  created() {
    this.marticle = this.article
    this.fadeIn()
  },
  data() {
    return {
      vismain: false,
      marticle: null,
      imbase: process.env.VUE_APP_IMAGEBASE,
      imloaded: false
    }
  },
  methods: {
    async fadeIn() {
      if(this.marticle){
        this.vismain = true
      }
    },
    async switch(){
      this.vismain = false
      await waitfor(400)
      this.vismain = false
      this.marticle = this.article
    },
    handleLoadImage() {
      this.vismain = true
    },
    getImageFile(image) {
      let fname = image.filename.replace(/\.gif/g, '.jpg')
      return this.imbase + '/525/' + fname
    }
  },
  watch: {
    article() {
      this.switch()
    }
  }
}
</script>

<style>
.mainarea{
  opacity: 0;
  transition:opacity 0.4s linear;
}

.mainarea.viz{
  opacity:1;
}

</style>
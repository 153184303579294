<template>
  <nav-header :section="this.sectionCode"/>
  <gallery-browser v-on:change="showGalleryArticle"  />
  <main class="main">
    <div class="text-area" v-html="article"></div>
  </main>
  <footer-vue />
</template>

<script>
import NavHeader from '../components/NavHeader.vue'
import GalleryBrowser from '../components/GalleryBrowser.vue'
import FooterVue from '../components/FooterView.vue'
import mdata from '../data/mdata.js'
import { linkify } from '../utils.js'
export default {
  props: {
    sectionCode: String
  },
  components: {
    NavHeader,
    GalleryBrowser,
    FooterVue
  },
  data() {
    return {
      article: mdata.getArticleBody(this.sectionCode)
    }
  },
  methods: {
    showGalleryArticle(which) {
      let gallery = mdata.getCurrentGallerySection()
      console.log('gallery is', gallery)
      this.$router.push('/my-work/' + linkify(gallery.title + '/' + which))
      console.log('which is', which)
    }
  },
  watch: {
    sectionCode() {
      this.article = mdata.getArticleBody(this.sectionCode)
    }
  }
}
</script>

<style>
.text-area{
  white-space: pre-line;
  margin-left:191px;
  margin-top:20px;
}

@media screen and (max-width:640px) {
  .text-area{
    margin:20px;
    box-sizing: border-box;
    max-width:100%;
  }
}
</style>
import balson from './balson.json'
import { getSectionByUrl } from '../utils.js'
import { getSectionIdByUrl} from '../utils.js'

function getSection(url) {
  return getSectionByUrl(balson, url)
}

function getSectionId(url) {
  return getSectionIdByUrl(balson, url)
}

function getArticleBody(url) {
  let s = getSection(url)
  if(s) {
    if(s.articles.length){
      return s.articles[0].body
    }
  }
}

function getGallery(which) {
  let s = getSection('my-work')
  let articles = s.sections[which].articles
  articles.sort((el1, el2) => {
    return el1.rating - el2.rating
  })
  return articles
}

function getGallerySection(which) {
  let s = getSection('my-work')
  return s.sections[which]
}

function getGalleryByName(aname) {
  let s = getSection('my-work')
  return getSectionByUrl(s.sections, aname)
}

function all() {
  return balson
}

function galleryIndex(agal) {
  let s = getSection('my-work')
  return s.sections.findIndex(el => el.section_id === agal.section_id)
}

let curgal = 0

function getCurgal() {
  return curgal
}

function setCurgal(num) {
  curgal = num
}

function getCurrentGallery() {
  return getGallery(curgal)
}

function getCurrentGallerySection() {
  return getGallerySection(curgal)
}

export default { getSection, getSectionId, getArticleBody, all, getGallery, getGalleryByName, galleryIndex, getCurgal, setCurgal, getCurrentGallery, getCurrentGallerySection }

async function waitfor(ms) {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        resolve()
      }, ms)
    }
    catch(err) {
      reject(err)
    }
  })
}

function linkify(title) {
  return title.toLowerCase().replace(/ /g,'-')
}


function getSectionByUrl(balson, url) {
  let ss = balson.filter(el => {
    let lf = linkify(el.title)
    return lf === url
  })
  if(ss.length) return ss[0]
  return null
}

function getSectionIdByUrl(balson, url) {
  let s = getSectionByUrl(balson, url)
  if(s) {
    return s.section_id
  }
  return 0
}

export { waitfor, getSectionByUrl, getSectionIdByUrl, linkify }
import { createRouter, createWebHistory } from 'vue-router'
import Splash from '../views/SplashView.vue'
import StandardView from '../views/StandardView.vue'
import GalleryView from '../views/GalleryView.vue'

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/about-me',
    name: 'About',
    component: StandardView,
    props: {
      sectionCode: 'about-me'
    }
  },
  {
    path: '/exhibitions',
    name: 'Exhibitions',
    component: StandardView,
    props: {
      sectionCode: 'exhibitions'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: StandardView,
    props: {
      sectionCode: 'contact'
    }
  },
  {
    path: '/my-work/:galleryName/:item?',
    name: 'Gallery',
    component: GalleryView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
